<template>
    <div>
        <Header>
            <template v-slot:header-title>
                Video Library
            </template>
            <template v-slot:header-strapline>
                We have created an extensive library of videos that aim to explain what it is we do, as quickly and as easy as possible. Our aim here is for our videos to be informative but remain respectful of both your time and the planet.
            </template>
        </Header>
        <section class="video-grid-section">
            <div class="video-grid-controls"></div>
            <div class="video-grid-filter-controls">
                <div class="filter-control video-type"
                     @click="FilterVideos('all')"
                     :class="{active: showAll}">
                     All
                </div>
                <div class="filter-control video-type"
                     @click="FilterVideos('styling')"
                     :class="{active: showStyling}">
                     Styling
                </div>
                <div class="filter-control video-type"
                     @click="FilterVideos('operations')"
                     :class="{active: showOperations}">
                     Operations
                </div>
                <div class="filter-control video-type"
                     @click="FilterVideos('manage')"
                     :class="{active: showManage}">
                     Manage
                 </div>
                <div class="filter-control video-type"
                     @click="FilterVideos('marketplace')"
                     :class="{active: showMarketplace}">
                     Marketplace
                </div>
                <div class="filter-control video-type"
                     @click="FilterVideos('finance')"
                     :class="{active: showFinance}">
                     Finance
                </div>
                <!-- List -->
                <div class="filter-control layout" 
                     @click="ChangeVideoLayout('list')"
                     :class="{active: listActive}">
                    <i class="bi bi-list-ul"></i>
                </div>
                <!-- Grid -->
                <div class="filter-control layout"
                     @click="ChangeVideoLayout('grid')"
                     :class="{active: gridActive}">
                    <i class="bi bi-grid-fill"></i>
                </div>
            </div>
            <VideoGrid></VideoGrid>
        </section>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import VideoGrid from "../components/video-library/VideoGrid.vue";

export default {
    components: { Header, VideoGrid },
    metaInfo() {
        return {
            title: 'Video Library | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'We have created an extensive library of videos that aim to explain what it is we do, as quickly and as easy as possible. Our aim here is for our videos to be informative but remain respectful of both your time and the planet.'
                },
                {
                    property: 'og:title',
                    content: 'Video Library | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'We have created an extensive library of videos that aim to explain what it is we do, as quickly and as easy as possible. Our aim here is for our videos to be informative but remain respectful of both your time and the planet.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/resources/video-library'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/resources/video-library'
                }
            ]
        }
    },
    data: () => ({
        showAll: true, showStyling: false, showOperations: false, showManage: false, showMarketplace: false, showFinance: false,
        listActive: false, gridActive: false,
        windowWidth: window.innerWidth, windowHeight: window.innerHeight
    }),
    created() {
        window.addEventListener("resize", this.onResize);
        this.windowWidth < 1250 ? this.listActive = true : this.gridActive = true;
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    watch: {
        windowWidth: function() {
            if (this.windowWidth >= 1250) {
                this.ChangeVideoLayout('grid');
            } else {
                this.ChangeVideoLayout('list');
            }
        },
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        FilterVideos(type) {
            switch(type) {
                case "all":
                    this.FilterAllVideos();
                    break;
                case "styling":
                    this.FilterStylingVideos();
                    break;
                case "operations":
                    this.FilterOperationsVideos();
                    break;
                case "manage":
                    this.FilterManageVideos();
                    break;
                case "marketplace":
                    this.FilterMarketplaceVideos();
                    break;
                case "finance":
                    this.FilterFinanceVideos();
                    break;
                default:
                    break;
            }
        },
        FilterAllVideos() {
            this.$root.$emit('filter-videos', 'all');
            this.showAll = true;
            this.showStyling = false;
            this.showOperations = false;
            this.showManage = false;
            this.showMarketplace = false;
            this.showFinance = false;
        },
        FilterStylingVideos() {
            this.$root.$emit('filter-videos', 'styling');
            this.showStyling = true;
            this.showAll = false;
            this.showOperations = false;
            this.showManage = false;
            this.showMarketplace = false;
            this.showFinance = false;
        },
        FilterOperationsVideos() {
            this.$root.$emit('filter-videos', 'operations');
            this.showOperations = true;
            this.showAll = false;
            this.showStyling = false;
            this.showManage = false;
            this.showMarketplace = false;
            this.showFinance = false;
        },
        FilterManageVideos() {
            this.$root.$emit('filter-videos', 'manage');
            this.showManage = true;
            this.showAll = false;
            this.showStyling = false;
            this.showOperations = false;
            this.showMarketplace = false;
            this.showFinance = false;
        },
        FilterMarketplaceVideos() {
            this.$root.$emit('filter-videos', 'marketplace');
            this.showMarketplace = true;
            this.showAll = false;
            this.showStyling = false;
            this.showOperations = false;
            this.showManage = false;
            this.showFinance = false;
        },
        FilterFinanceVideos() {
            this.$root.$emit('filter-videos', 'finance');
            this.showFinance = true;
            this.showAll = false;
            this.showStyling = false;
            this.showOperations = false;
            this.showManage = false;
            this.showMarketplace = false;
        },
        ChangeVideoLayout(layout) {
            switch(layout) {
                case "list":
                    this.$root.$emit('change-video-layout', 'list');
                    this.listActive = true;
                    this.gridActive = false;
                    break;
                case "grid":
                    this.$root.$emit('change-video-layout', 'grid');
                    this.gridActive = true;
                    this.listActive = false;
                    break;
                default:
                    break;
            }
        },
    }
}
</script>

<style scoped>
.video-grid-section {
    background-color: #f4f7f9;
    padding-bottom: 160px;
}
.video-grid-section .video-grid-controls {
    position: relative;
    background-color: #fff;
    height: 70px;
    margin-bottom: 5px;
}
.video-grid-section .video-grid-filter-controls {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 0;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    overflow-x: auto;
}
.video-grid-section .video-grid-filter-controls .filter-control {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.15s ease, color 0.15s ease;
    user-select: none;
    border-radius: 10px;
    white-space: nowrap;
}
.video-grid-section .video-grid-filter-controls .filter-control:hover {
    color: var(--brand-accent-blue);
}
.video-grid-section .video-grid-filter-controls .filter-control:not(:first-child) {
    margin-left: 10px;
}
.video-grid-section .video-grid-filter-controls .filter-control.active {
    color: var(--brand-accent-blue);
}
.video-grid-section .video-grid-filter-controls .filter-control.layout {
    display: none;
}
.video-grid-section .video-grid-filter-controls .filter-control::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--brand-accent-blue);
    border-radius: 50px;
    bottom: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s ease;
}
.video-grid-section .video-grid-filter-controls .filter-control:hover::after,
.video-grid-section .video-grid-filter-controls .filter-control.active::after {
    width: 40%;
}
.video-grid-section .video-grid-filter-controls .filter-control i {
    font-size: 22px;
}
.video-grid-section .video-grid-filter-controls .filter-control.active {
    color: var(--brand-accent-blue);
}
@media only screen and (max-width: 670px) {
    .video-grid-section .video-grid-filter-controls {
        padding: 5px 20px;
    }
}
@media only screen and (min-width: 670px) {
    .video-grid-section .video-grid-filter-controls {
        justify-content: center;
    }
}
@media only screen and (min-width: 1250px) {
    .video-grid-section .video-grid-filter-controls .filter-control.layout {
        display: block;
    }
}
</style>