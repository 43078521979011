<template>
    <div class="video-player">
        <iframe
            width="960"
            height="540"
            :src="src"
            class="video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        >
        </iframe>
    </div>
</template>

<script>
export default {
    props: ['src']
}
</script>

<style scoped>
.video-player {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    position: relative;
}
.video-player .video {
    border-radius: 10px;
    box-shadow: 0 3px 50px 0 rgba(0,0,0,0.3);
    width: 100%;
    max-width: 960px;
    height: 100%;
    max-height: 540px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(25%);
}
</style>