<template>
    <div>
        <div class="search-bar-wrapper">
            <div class="relative search-bar-container">
                <input type="text" class="video-search" placeholder="Search videos" v-model="search">
                <div class="reset-text-button" @click="ClearSearchBar">
                    <i class="bi bi-x-circle-fill"></i>
                    <span id="tooltip">Clear Search</span>
                </div>
            </div>
        </div>
        <!-- Show all of the videos when the search bar is empty -->
        <div class="video-grid-container" v-if="search == ''">
            <div class="video-wrapper" v-for="(video, index) in videos" :key="index">
                <div class="video"
                     :class="{'grid': layout === 'grid', 'list': layout === 'list'}"
                     @click="PlayVideo(video.video_id)">
                    <div class="video-thumbnail" v-if="(video.thumbnail !== '[none]')">
                        <img :src="video.thumbnail" :alt="video.title">
                        <div class="video-play-icon-wrapper absolute">
                            <i class="bi bi-play-circle-fill video-play-icon"></i>
                        </div>
                        <div class="video-length">{{video.duration}}</div>
                    </div>
                    <!-- <div class="video-date">{{video.date}}</div> -->
                    <div class="video-information">
                        <div class="video-title-icon">
                            <div class="video-title">{{video.title}}</div>
                        </div>
                        <div class="video-tags flex align-center" v-if="video.tags">
                            <div class="tag" v-for="tag in video.tags" :key="tag">
                                {{tag}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="video-description">{{video.description}}</div> -->
                </div>
            </div>
        </div>
         <!-- Show the resulting videos when there is a search term -->
        <div class="video-grid-container" v-if="search != ''">
            <div class="video-wrapper" v-for="video of SearchVideos" :key="video">
                <div class="video"
                     :class="{'grid': layout === 'grid', 'list': layout === 'list'}"
                     @click="PlayVideo(video.video_id)">
                    <div class="video-thumbnail" v-if="(video.thumbnail !== '[none]')">
                        <img :src="video.thumbnail" :alt="video.title">
                        <div class="video-play-icon-wrapper absolute">
                            <i class="bi bi-play-circle-fill video-play-icon"></i>
                        </div>
                        <div class="video-length">{{video.duration}}</div>
                    </div>
                    <!-- <div class="video-date">{{video.date}}</div> -->
                    <div class="video-information">
                        <div class="video-title-icon">
                            <div class="video-title">{{video.title}}</div>
                        </div>
                        <div class="video-tags flex align-center" v-if="video.tags">
                            <div class="tag" v-for="tag in video.tags" :key="tag">
                                {{tag}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="video-description">{{video.description}}</div> -->
                </div>
            </div>
        </div>
        <!-- #region Video Player -->
        <div class="video-player-wrapper" v-if="showVideoPlayer">
            <VideoPlayer :src="videoSource" v-on-clickaway="HideVideoPlayer"></VideoPlayer>
            <div class="close-video" @click="showVideoPlayer = false">
                <i class="bi bi-x-lg"></i>
            </div>
        </div>
        <!-- #endregion Video Player -->
        <!-- No Results Found -->
        <div class="no-results-wrapper" v-show="noResults">
            <div class="no-results-icon">
                <i class="bi bi-search"></i>
            </div>
            <div class="no-results-heading">
                No results found.
            </div>
            <div class="no-results-text">
                We can't find any item matching your search.
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import VideoPlayer from "../video-library/VideoPlayer.vue";
import axios from "axios";
import dayjs from "dayjs";

export default {
    mixins: [ clickaway ],
    components: {
        VideoPlayer
    },
    data: () => ({
        videoData: [],
        videos: [],
        search: "",
        filterVideoType: "",
        videoSource: "", showVideoPlayer: false,
        noResults: false,
        layout: "grid",
        windowWidth: window.innerWidth, windowHeight: window.innerHeight
    }),
    watch: {
        videos () {
            this.CheckForFilteredVideoResults();
        },
        SearchVideos () {
            this.CheckForFilteredVideoResults();
        },
        layout: function() {
            if (this.layout == 'grid') {
                document.querySelector('.video-grid-container').style.gridTemplateColumns = "repeat(auto-fill, 400px)";
            }
            if (this.layout == 'list') {
                document.querySelector('.video-grid-container').style.gridTemplateColumns = "100%";
            }
        },
    },
    created () {
        this.videos = this.videoData;
        window.addEventListener("resize", this.onResize);
        this.windowWidth < 1250 ? this.layout = "list" : this.layout = "grid";
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        SearchVideos: function() {
            if (this.search != "") {
                return this.videoData.filter((video) => {
                    return video.title
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                });
            } else {
                return this.videoData;
            }
            
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        VideoFilter() {
            let tempData = JSON.parse(JSON.stringify(this.videoData));

            if (this.filterVideoType != "") {
                tempData = tempData.filter((video) => {
                    const tags = video.tags.map(tag => {
                        return tag.toLowerCase();
                    })
                    return tags.includes(this.filterVideoType);
                });
            }

            this.videos = tempData;
        },
        PlayVideo(src) {
            this.videoSource = String(`https://www.youtube-nocookie.com/embed/${src}?rel=0`);
            this.showVideoPlayer = true;
        },
        HideVideoPlayer() {
            this.showVideoPlayer = false;
        },
        CheckForFilteredVideoResults() {
            if (this.SearchVideos.length === 0 && this.videos.length === 0) {
                this.noResults = true;
            }
            if (this.videos.length > 0 && this.SearchVideos.length === 0) {
                this.noResults = true;
            }
            if (this.SearchVideos.length > 0 && this.videos.length === 0) {
                this.noResults = false;
                if (this.search === "") {
                    this.noResults = true;
                }
            }
            if (this.SearchVideos.length > 0 && this.videos.length > 0) {
                this.noResults = false;
            }
        },
        FormatDate(date) {
            const newDate = dayjs(new Date(date)).format("Do MMMM YYYY");
            return newDate;
        },
        FormatTags(tags) {
            const newTags = [];
            if (tags == null) { return newTags; }
            const validTags = ["Styling", "styling", "Operations", "operations", "Manage", "manage", "Marketplace", "marketplace", "Finance", "finance"];
            for (let tag of tags) {
                if (validTags.includes(tag)) {
                    newTags.push(tag);
                }
            }
            return newTags;
        },
        async GetVideoData() {
            try {
                const getVideos = await axios.get(`https://${location.host}/api/videos`);
                // console.log(getVideos);

                if (getVideos.status === 200) {
                    for (let video of getVideos.data) {
                        this.videoData.push(
                            {
                                _id: video._id,
                                title: video.title,
                                channel: video.channelTitle,
                                description: video.description,
                                video_id: video.id,
                                date: this.FormatDate(video.publishedAt),
                                duration: video.duration,
                                tags: this.FormatTags(video.tags),
                                thumbnail : video.thumbnail
                            }
                        );
                    }
                }

            } catch (error) {
                console.log(new Error(error));
            }
        },
        ClearSearchBar() {
            this.search = "";
        },
    },
    mounted: function () {
        this.GetVideoData();
        this.$root.$on('filter-videos', (type) => {
            this.filterVideoType = type;

            if (type == "all") {
                this.filterVideoType = "";
                this.videos = this.videoData;
            }
            else {
                this.VideoFilter();
            }
        })
        this.$root.$on('change-video-layout', (layout) => {
            if (layout == 'grid') {
                this.layout = "grid";
                document.querySelector('.video-grid-container').style.gridTemplateColumns = "repeat(auto-fill, 400px)";
            } else if (layout == "list") {
                this.layout = "list";
                document.querySelector('.video-grid-container').style.gridTemplateColumns = "100%";
            }
        })
    }
}
</script>

<style scoped>
.video-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-gap: 20px;
    justify-content: center;
    max-width: 1240px;
    margin: 0 auto;
}
.search-bar-wrapper {
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.search-bar-wrapper .search-bar-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}
.search-bar-wrapper .search-bar-container .video-search {
    position: relative;
    border: none;
    border-radius: 6px;
    padding: 25px 70px 25px 25px;
    width: 100%;
    max-width: 800px;
    z-index: 2;
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.16);
    font-size: 18px;
    color: var(--text-dark-terniary);
    font-weight: 300;
    font-family: inherit;
    transition: box-shadow 0.4s ease;
}
.search-bar-wrapper .search-bar-container .video-search:focus {
    outline: none !important;
    box-shadow: 0 3px 52px 0 #00bcc536 !important;
}
.search-bar-wrapper .search-bar-container .reset-text-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0 25px;
    right: 14px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    color: var(--text-dark-secondary);
    font-size: 20px;
    z-index: 2;
}
.search-bar-wrapper .search-bar-container #tooltip {
    position: absolute;
    top: -20px;
    width: 100px;
    font-size: 14px;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 3px;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: opacity 0.2s ease,
                transform 0.2s ease;
}
.search-bar-wrapper .search-bar-container .reset-text-button:hover #tooltip {
    opacity: 1;
    transform: translateY(0px);
}
.search-bar-wrapper .search-bar-container .video-search.icon {
    background-image: url("../../assets/search-icon.svg");
    background-size: 25px;
    background-repeat: no-repeat;
}
.video-grid-container .video-wrapper:hover .video {
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.08);
}
.video-grid-container .video {
    padding: 10px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}
/* Video: List Layout */
.video-grid-container .video.list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.video-grid-container .video.list .video-thumbnail {
    margin-bottom: 0;
    margin-right: 10px;
    max-width: 125px;
    height: 74px;
    flex-shrink: 0;
}
.video-grid-container .video.list .video-thumbnail img {
    width: 125px;
    height: 94px;
}
.video-grid-container .video.list .video-information {
    overflow: hidden;
}
.video-grid-container .video.list .video-thumbnail .video-play-icon {
    font-size: 32px;
}
.video-grid-container .video.list .video-thumbnail .video-length {
    bottom: 3px;
    right: 3px;
    padding: 4px 8px;
}
.video-grid-container .video.list .video-title-icon {
    margin-bottom: 10px;
}
.video-grid-container .video.list .video-title-icon .video-title {
    font-size: 16px;
}
.video-grid-container .video.list .video-tags {
    margin-bottom: 0;
    overflow-x: auto;
}
.video-grid-container .video.list .video-tags .tag {
    padding: 8px 10px;
    font-size: 12px;
    font-weight: 400;
}
.video-grid-container .video .video-title-icon {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
}
/* Video: Grid Layout */
.video-grid-container .video.grid .video-thumbnail {
    margin-bottom: 20px;
    max-width: 380px;
    height: 225px;
}
.video-grid-container .video.grid .video-thumbnail img {
    width: 380px;
    height: 285px;
}
.video-grid-container .video.grid .video-thumbnail .video-play-icon {
    font-size: 60px;
}
.video-grid-container .video.grid .video-thumbnail .video-length {
    bottom: 10px;
    right: 10px;
    padding: 8px 14px;
}
.video-grid-container .video.grid .video-title-icon {
    margin-bottom: 40px;
}
.video-grid-container .video.grid .video-tags {
    flex-wrap: wrap;
}
.video-grid-container .video.grid .video-tags .tag {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.video-grid-container .video .video-thumbnail {
    background-color: #e9e9e9;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    cursor: pointer;
}
.video-grid-container .video-wrapper:hover .video .video-thumbnail img {
    transform: scale(1.05);
    filter: brightness(90%);
}
.video-grid-container .video .video-thumbnail img {
    min-width: 100%;
    min-height: 100%;
    transition: transform 0.2s ease,
                filter 0.2s ease;
}
.video-grid-container .video .video-thumbnail .video-play-icon {
    color: white;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(50px);
    transition: color 0.2s ease,
                transform 0.2s ease;
}
.video-grid-container .video-wrapper:hover .video .video-thumbnail .video-play-icon {
    color: var(--dark-default);
    transform: translateY(0px);
}
.video-grid-container .video .video-thumbnail .video-length {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 8px 14px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    font-size: 14px;
    font-weight: 500;
}
.video-grid-container .video .video-tags {
    padding: 0 10px;
    display: flex;
}
.video-grid-container .video .video-tags .tag {
    padding: 10px 15px;
    background: #007bfc14;
    border-radius: 50px;
    color: var(--brand-accent-blue);
    margin-right: 10px;
    text-transform: capitalize;
}
.video-grid-container .video .video-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
}
.video-player-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #000000c4;
}
.video-player-wrapper .close-video {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: white;
    font-size: 32px;
}
.no-results-wrapper {
    max-width: var(--max-width);
    margin: 100px auto 0 auto;
    display: grid;
    grid-gap: 20px;
    justify-content: center;
    text-align: center;
}
.no-results-wrapper .no-results-icon i {
    font-size: 75px;
    color: var(--text-dark-secondary);
}
.no-results-wrapper .no-results-heading {
    font-size: 20px;
    font-weight: 500;
}
.no-results-wrapper .no-results-text {
    font-size: 20px;
    color: var(--text-dark-secondary);
    max-width: 300px;
}
</style>